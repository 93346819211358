import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
import "../node_modules/@syncfusion/ej2-layouts/styles/material.css";
import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
import "../node_modules/@syncfusion/ej2-react-kanban/styles/material.css";
import "bootstrap/dist/css/bootstrap.min.css";

// SyncFusion License Key
import { registerLicense } from "@syncfusion/ej2-base";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Spin } from "antd";

registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NCaF1cXGdCf1NpRGVGfV5ycEVEalhRTnVbUiweQnxTdEFjUH1dcHVWTmBbUkN1Vg=="
);

const root = ReactDOM.createRoot(document.getElementById("root"));
const App = lazy(() => import("./App"));

root.render(
  <RouterProvider
    router={createBrowserRouter([
      {
        path: "*",
        element: (
          <Suspense
            fallback={
              <Spin style={{ minHeight: "100vh" }} size="large">
                <div className="appLoader" />
              </Spin>
            }
          >
            <App />
          </Suspense>
        ),
      },
    ])}
  ></RouterProvider>
);
